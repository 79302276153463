var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "el-def",
                      attrs: { placeholder: "제목/내용" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.refetchData()
                        },
                      },
                      model: {
                        value: _vm.query.filterStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "filterStr", $$v)
                        },
                        expression: "query.filterStr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 등록 리스트 [" + _vm._s(_vm.totalRecords) + "] 건 "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "refNoticeTable",
            staticClass: "position-relative",
            staticStyle: { "max-height": "660px" },
            attrs: {
              "primary-key": "id",
              items: _vm.fetchNotices,
              fields: _vm.tableColumns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.isSortDesc,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.isSortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.isSortDesc = $event
              },
              "row-selected": _vm.onRowSelectedNotice,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(createDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.value,
                              "YYYY-MM-DD HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetNoticeData()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { disabled: _vm.isSaving, variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveNotice()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeNotice()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "Trash2Icon" },
                      }),
                      _c("span", [_vm._v("삭제")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "formRules" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: { label: "제목", "label-for": "notice-title" },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제목", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "clientName",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.noticeData.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.noticeData, "title", $$v)
                                        },
                                        expression: "noticeData.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "컨텐츠",
                            "label-for": "notice-is-upload-contents",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.contentsRegType,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.noticeData.isUploadContents,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.noticeData,
                                  "isUploadContents",
                                  $$v
                                )
                              },
                              expression: "noticeData.isUploadContents",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.noticeData.isUploadContents
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { "label-for": "" } },
                            [
                              _c("b-form-file", {
                                ref: "contentFile",
                                attrs: {
                                  accept: "text/plain,.html",
                                  placeholder: "파일 찾기",
                                },
                                on: { input: _vm.readContentFile },
                              }),
                              _c("validation-provider", {
                                attrs: { name: "컨텐츠", rules: "required" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-input", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: false,
                                                expression: "false",
                                              },
                                            ],
                                            model: {
                                              value: _vm.noticeData.contents,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.noticeData,
                                                  "contents",
                                                  $$v
                                                )
                                              },
                                              expression: "noticeData.contents",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1345701352
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-sm-50",
                              attrs: {
                                variant: "primary",
                                disabled:
                                  _vm.noticeData.contents === null ||
                                  _vm.noticeData.contents.length === 0,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showModal = true
                                },
                              },
                            },
                            [_c("span", [_vm._v("미리보기")])]
                          ),
                          _c(
                            "b-modal",
                            {
                              attrs: {
                                id: "noticePreview",
                                title: "HTML 미리보기",
                              },
                              model: {
                                value: _vm.showModal,
                                callback: function ($$v) {
                                  _vm.showModal = $$v
                                },
                                expression: "showModal",
                              },
                            },
                            [
                              _c("iframe", {
                                ref: "previewFrame",
                                attrs: {
                                  src: _vm.previewUrl,
                                  frameborder: "0",
                                  width: "1264",
                                  height: "740",
                                },
                                on: { load: _vm.loadPreview },
                              }),
                            ]
                          ),
                          _vm.noticeData.contents !== null &&
                          _vm.noticeData.contents.length > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-sm-50",
                                  attrs: { variant: "outline-dark" },
                                  on: { click: _vm.downloadContent },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "DownloadIcon" },
                                  }),
                                  _c("span", [_vm._v(" 다운로드")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "10" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { "label-for": "" } },
                            [
                              _c("validation-provider", {
                                attrs: { name: "컨텐츠", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("tinymce-editor", {
                                          model: {
                                            value: _vm.noticeData.contents,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.noticeData,
                                                "contents",
                                                $$v
                                              )
                                            },
                                            expression: "noticeData.contents",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "첨부파일",
                            "label-for": "notice-files",
                          },
                        },
                        [
                          _c("b-form-file", {
                            ref: "attachFiles",
                            attrs: { multiple: "", placeholder: "파일 찾기" },
                            on: { input: _vm.inputFiles },
                            model: {
                              value: _vm.attachFiles,
                              callback: function ($$v) {
                                _vm.attachFiles = $$v
                              },
                              expression: "attachFiles",
                            },
                          }),
                          _vm._l(
                            _vm.noticeData.attachFiles,
                            function (item, idx) {
                              return _c(
                                "div",
                                { key: idx },
                                [
                                  _c(
                                    "b-card-text",
                                    { staticClass: "my-50 text-primary" },
                                    [
                                      _vm._v(" " + _vm._s(item.fileName) + " "),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "ml-1",
                                          staticStyle: {
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            variant: "outline-secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.deleteAttachFile(
                                                item.fileId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-50",
                                            attrs: { icon: "Trash2Icon" },
                                          }),
                                          _c("span", [_vm._v("삭제")]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-sm-50",
                                          attrs: { variant: "outline-dark" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.downloadAttachFile(
                                                item.fileId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            staticClass: "mr-50",
                                            attrs: { icon: "DownloadIcon" },
                                          }),
                                          _c("span", [_vm._v("다운로드")]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "팝업 여부",
                            "label-for": "notice-is-popup",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.noticeData.isPopup,
                              callback: function ($$v) {
                                _vm.$set(_vm.noticeData, "isPopup", $$v)
                              },
                              expression: "noticeData.isPopup",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.noticeData.isPopup
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업 게시 일자(*팝업 사용 시)",
                                "label-for": "popup-date-fr",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 게시 일",
                                  rules: {
                                    required: _vm.noticeData.isPopup,
                                    before: _vm.noticeData.popupDateTo,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              placeholder: "팝업 게시 일",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.noticeData.popupDateFr,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.noticeData,
                                                  "popupDateFr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "noticeData.popupDateFr",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "팝업 기간은 진행 기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2774704444
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업 종료 일자(*팝업 사용 시)",
                                "label-for": "popup-date-to",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 종료 일",
                                  rules: {
                                    required: _vm.noticeData.isPopup,
                                    after: _vm.noticeData.popupDateFr,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        var failedRules = ref.failedRules
                                        return [
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              placeholder: "팝업 종료 일",
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value: _vm.noticeData.popupDateTo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.noticeData,
                                                  "popupDateTo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "noticeData.popupDateTo",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    failedRules.hasOwnProperty(
                                                      "dateBetween"
                                                    )
                                                      ? "팝업 기간은 진행 기간 이내로 제한됩니다."
                                                      : errors[0]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3822790890
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "팝업다시보지않기(기준)",
                                "label-for": "notice-popup-again-days",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업다시보지않기(기준)",
                                  rules: "required",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              id: "notice-popup-again-days",
                                              options: _vm.codes.popupAgainDays,
                                              placeholder: "선택",
                                              reduce: function (option) {
                                                return option.code
                                              },
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            model: {
                                              value:
                                                _vm.noticeData.popupAgainDays,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.noticeData,
                                                  "popupAgainDays",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "noticeData.popupAgainDays",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2635826898
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.noticeData.isPopup
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "팝업 이미지 (※ 이미지 사이즈는 650 x 380 이내로 제작하세요.)",
                                "label-for": "main-thumbnail-files",
                              },
                            },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  name: "팝업 이미지",
                                  rules: {
                                    required:
                                      _vm.noticeData.isPopup &&
                                      !_vm.noticeData.popupImageFileId,
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("b-form-file", {
                                            attrs: {
                                              accept: "image/*",
                                              placeholder: "파일 찾기",
                                              disabled:
                                                !_vm.noticeData.popupDateFr,
                                              state:
                                                errors.length > 0
                                                  ? false
                                                  : null,
                                            },
                                            on: { input: _vm.inputPopupImage },
                                            model: {
                                              value: _vm.popupImageFile,
                                              callback: function ($$v) {
                                                _vm.popupImageFile = $$v
                                              },
                                              expression: "popupImageFile",
                                            },
                                          }),
                                          _c(
                                            "small",
                                            { staticClass: "text-danger" },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2070791737
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", md: "3" } },
                        [
                          _vm.noticeData.popupImageFileId
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mr-sm-50",
                                  staticStyle: { "margin-top": "21px" },
                                  attrs: { variant: "outline-dark" },
                                  on: { click: _vm.downloadPopupImage },
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "mr-50",
                                    attrs: { icon: "DownloadIcon" },
                                  }),
                                  _c("span", [_vm._v("다운로드")]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "상단 고정",
                            "label-for": "notice-is-fixed",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            on: { change: _vm.changeIsFixed },
                            model: {
                              value: _vm.noticeData.isFixed,
                              callback: function ($$v) {
                                _vm.$set(_vm.noticeData, "isFixed", $$v)
                              },
                              expression: "noticeData.isFixed",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "상단 고정 유형(*상단 고정 시 필수)",
                            "label-for": "notice-is-fixed",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "상단 고정 유형",
                              rules: { required: _vm.noticeData.isFixed },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-radio-group", {
                                      attrs: {
                                        disabled: !_vm.noticeData.isFixed,
                                        options: _vm.codes.noticeFixedType,
                                        "value-field": "code",
                                        "text-field": "label",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.noticeData.fixedType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.noticeData,
                                            "fixedType",
                                            $$v
                                          )
                                        },
                                        expression: "noticeData.fixedType",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "공개 여부",
                            "label-for": "notice-is-public",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.public,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.noticeData.isPublic,
                              callback: function ($$v) {
                                _vm.$set(_vm.noticeData, "isPublic", $$v)
                              },
                              expression: "noticeData.isPublic",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "10" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "댓글 기능",
                            "label-for": "notice-allow-comments",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              options: _vm.codes.use,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.noticeData.allowComments,
                              callback: function ($$v) {
                                _vm.$set(_vm.noticeData, "allowComments", $$v)
                              },
                              expression: "noticeData.allowComments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }