<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="등록일(To)"
            /> -->
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="제목/내용"
              @keyup.enter="refetchData()"
            />
            <!-- <b-form-input
              v-model="query.userName"
              class="el-def"
              placeholder="작성자"
              @keyup.enter="refetchData()"
            /> -->
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              등록 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refNoticeTable"
        primary-key="id"
        :items="fetchNotices"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        style="max-height: 660px;"
        @row-selected="onRowSelectedNotice"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetNoticeData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규</span>
          </b-button>
          <b-button
            :disabled="isSaving"
            variant="primary"
            class="ml-1"
            @click.prevent="saveNotice()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removeNotice()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="formRules">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="제목"
              label-for="notice-title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="clientName"
                  v-model="noticeData.title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="컨텐츠"
              label-for="notice-is-upload-contents"
            >
              <b-form-radio-group
                v-model="noticeData.isUploadContents"
                :options="codes.contentsRegType"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="noticeData.isUploadContents">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label-for=""
            >
              <b-form-file
                ref="contentFile"
                accept="text/plain,.html"
                placeholder="파일 찾기"
                @input="readContentFile"
              />
              <validation-provider
                #default="{ errors }"
                name="컨텐츠"
                rules="required"
              >
                <b-form-input
                  v-show="false"
                  v-model="noticeData.contents"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              variant="primary"
              class="mr-sm-50"
              :disabled="noticeData.contents === null || noticeData.contents.length === 0"
              @click="showModal = true"
            >
              <span>미리보기</span>
            </b-button>
            <b-modal
              id="noticePreview"
              v-model="showModal"
              title="HTML 미리보기"
            >
              <iframe
                ref="previewFrame"
                :src="previewUrl"
                frameborder="0"
                width="1264"
                height="740"
                @load="loadPreview"
              />
            </b-modal>
            <b-button
              v-if="noticeData.contents !== null && noticeData.contents.length > 0"
              variant="outline-dark"
              class="mr-sm-50"
              @click="downloadContent"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span> 다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label-for=""
            >
              <validation-provider
                #default="{ errors }"
                name="컨텐츠"
                rules="required"
              >
                <tinymce-editor
                  v-model="noticeData.contents"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="첨부파일"
              label-for="notice-files"
            >
              <b-form-file
                ref="attachFiles"
                v-model="attachFiles"
                multiple
                placeholder="파일 찾기"
                @input="inputFiles"
              />
              <div
                v-for="(item, idx) in noticeData.attachFiles"
                :key="idx"
              >
                <b-card-text
                  class="my-50 text-primary"
                >
                  {{ item.fileName }}
                  <b-button
                    variant="outline-secondary"
                    class="ml-1"
                    style="margin-right: 10px;"
                    @click.prevent="deleteAttachFile(item.fileId)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      class="mr-50"
                    />
                    <span>삭제</span>
                  </b-button>
                  <b-button
                    variant="outline-dark"
                    class="mr-sm-50"
                    @click.prevent="downloadAttachFile(item.fileId)"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    <span>다운로드</span>
                  </b-button>
                </b-card-text>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="팝업 여부"
              label-for="notice-is-popup"
            >
              <b-form-radio-group
                v-model="noticeData.isPopup"
                :options="codes.use"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="noticeData.isPopup">
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업 게시 일자(*팝업 사용 시)"
              label-for="popup-date-fr"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="팝업 게시 일"
                :rules="{
                  required: noticeData.isPopup,
                  before: noticeData.popupDateTo,
                }"
              >
                <b-form-datepicker
                  v-model="noticeData.popupDateFr"
                  placeholder="팝업 게시 일"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '팝업 기간은 진행 기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업 종료 일자(*팝업 사용 시)"
              label-for="popup-date-to"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="팝업 종료 일"
                :rules="{
                  required: noticeData.isPopup,
                  after: noticeData.popupDateFr,
                }"
              >
                <b-form-datepicker
                  v-model="noticeData.popupDateTo"
                  placeholder="팝업 종료 일"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('dateBetween')
                      ? '팝업 기간은 진행 기간 이내로 제한됩니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="팝업다시보지않기(기준)"
              label-for="notice-popup-again-days"
            >
              <validation-provider
                #default="{ errors }"
                name="팝업다시보지않기(기준)"
                rules="required"
              >
                <v-select
                  id="notice-popup-again-days"
                  v-model="noticeData.popupAgainDays"
                  :options="codes.popupAgainDays"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="noticeData.isPopup">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="팝업 이미지 (※ 이미지 사이즈는 650 x 380 이내로 제작하세요.)"
              label-for="main-thumbnail-files"
            >
              <validation-provider
                #default="{ errors }"
                name="팝업 이미지"
                :rules="{
                  required: noticeData.isPopup && !noticeData.popupImageFileId,
                }"
              >
                <b-form-file
                  v-model="popupImageFile"
                  accept="image/*"
                  placeholder="파일 찾기"
                  :disabled="!noticeData.popupDateFr"
                  :state="errors.length > 0 ? false : null"
                  @input="inputPopupImage"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
          >
            <b-button
              v-if="noticeData.popupImageFileId"
              variant="outline-dark"
              class="mr-sm-50"
              style="margin-top: 21px"
              @click="downloadPopupImage"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="3"
          >
            <b-form-group
              label="상단 고정"
              label-for="notice-is-fixed"
            >
              <b-form-radio-group
                v-model="noticeData.isFixed"
                :options="codes.use"
                value-field="code"
                text-field="label"
                @change="changeIsFixed"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="9"
          >
            <b-form-group
              label="상단 고정 유형(*상단 고정 시 필수)"
              label-for="notice-is-fixed"
            >
              <validation-provider
                #default="{ errors }"
                name="상단 고정 유형"
                :rules="{ required: noticeData.isFixed }"
              >
                <b-form-radio-group
                  v-model="noticeData.fixedType"
                  :disabled="!noticeData.isFixed"
                  :options="codes.noticeFixedType"
                  value-field="code"
                  text-field="label"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="공개 여부"
              label-for="notice-is-public"
            >
              <b-form-radio-group
                v-model="noticeData.isPublic"
                :options="codes.public"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="댓글 기능"
              label-for="notice-allow-comments"
            >
              <b-form-radio-group
                v-model="noticeData.allowComments"
                :options="codes.use"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col
            cols="12"
            md="10"
          >
            <b-form-group
              label="게시일"
              label-for="notice-create-date"
            >
              <b-form-input
                id="notice-create-date"
                :value="noticeData.createDate | $dateFormatter('YYYY-MM-DD HH:mm')"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row> -->
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import { BCardText, BFormFile } from 'bootstrap-vue'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TinymceEditor from '@/views/TinyMCE.vue'
import { saveAs } from "file-saver"

export default {
  components: {
    vSelect,
    BCardText,
    BFormFile,
    // vSelect,
    ValidationProvider,
    ValidationObserver,
    TinymceEditor,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview`
    const showModal = ref(false)
    const previewFrame = ref(null)
    const hasNoticeContents = ref(false)
    const popupImageFile = ref([])
    const attachFiles = ref([])

    const inputFiles = files => {
      attachFiles.value = files
    }

    const inputPopupImage = files => {
      popupImageFile.value = files
    }

    const noticeInitState = {
      allowComments: true,
      commentCount: 0,
      contents: null,
      attachFiles: [],
      deleteDate: null,
      id: null,
      isDelete: false,
      isFixed: false,
      fixedType: null,
      isPublic: true,
      isUploadContents: true,
      title: null,
      viewCnt: 0,
      isPopup: false,
      popupDateFr: null,
      popupDateTo: null,
      popupImageFileId: null,
      popupAgainDays: 0,
    }
    const noticeData = ref({ ...noticeInitState })

    const resetNoticeData = () => {
      Object.assign(noticeData.value, noticeInitState)

      refs.formRules.reset()
      refNoticeTable.value.clearSelected()

      if (contentFile.value && refs.contentFile) refs.contentFile.reset()
      if (attachFiles.value && refs.attachFiles) refs.attachFiles.reset()

      attachFiles.value = []
      contentFile.value = []
      popupImageFile.value = []
    }

    // Set Codes
    const codes = ref({
      noticeFixedType: null,
      use: [
        { label: '사용', code: true },
        { label: '사용안함', code: false },
      ],
      public: [
        { label: '공개', code: true },
        { label: '비공개', code: false },
      ],
      popupAgainDays: [
        { label: '다시 보지 않기', code: 0 },
        { label: '5일동안 보지 않기', code: 5 },
        { label: '10일동안 보지 않기', code: 10 },
      ],
      contentsRegType: [
        { label: '컨텐츠 업로드', code: true },
        { label: 'HTML 에디터', code: false },
      ],
    })

    const changeIsFixed = () => {
      if (!noticeData.value.isFixed) noticeData.value.fixedType = null
    }

    const fetchCodes = () => {
      axios.get('/fa/notice/codes')
        .then(rs => {
          const { noticeFixedType } = rs.data
          codes.value.noticeFixedType = noticeFixedType
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      filterStr: null,
      userName: null,
      createDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      createDateTo: dayjs().format('YYYY-MM-DD'),
    })
    // Query Data End.

    // Main Table Config
    const refNoticeTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const isSaving = ref(false)
    const tableColumns = [
      {
        key: 'id', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'title', label: '제목', sortable: true,
      },
      {
        key: 'viewCnt', label: '조회수', sortable: true, thStyle: { width: '6%' },
      },
      {
        key: 'createDate', label: '등록일시', sortable: true, thStyle: { width: '10%' },
      },
    ]
    const refetchData = () => {
      refNoticeTable.value.refresh()
    }
    const onRowSelectedNotice = items => {
      const item = items[0]

      if (item && item.id) {
        fetchNotice(item.id)
      }
    }
    // Main Table Config End.

    const contentFile = ref(null) // 메인 컨텐츠 파일
    const readContentFile = e => {
      if (!e) return

      if (e.type !== 'text/html') {
        pushToast('danger', '잘못된 파일 형식입니다.')

        contentFile.value.reset()
      } else {
        const reader = new FileReader()

        reader.onload = event => {
          noticeData.value.contents = event.target.result
        }

        reader.readAsText(e)
      }
    }

    const loadPreview = e => {
      if (e.type === 'load') previewFrame.value.contentWindow.postMessage(noticeData.value.contents, '*')
    }

    const downloadContent = () => {
      axios.get(`/fa/notice/${noticeData.value.id}/content`, { responseType: 'blob' })
        .then(rs => {
          const mimeType = {
            type: "text/plain",
          }

          const blob = new Blob([rs.data], mimeType)

          const fileName = noticeData.value.title

          saveAs(blob, `${fileName}.html`)
        })
        .catch(() => {
        })
    }

    const downloadPopupImage = () => {
      axios.get(`/fa/attachfile/${noticeData.value.popupImageFileId}`)
        .then(rs => {
          if (rs.data && rs.data.filePath) {
            window.open(rs.data.filePath)
          } else {
            pushToast('danger', '파일 조회에 실패하였습니다.')
          }
        })
        .catch(() => {
        })
    }

    const downloadAttachFile = fileId => {
      axios.get(`/fa/attachfile/${fileId}/download`, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])

          const name = rs.headers["content-disposition"]
            .split("filename=")[1]
            .replace(/"/g, "")

          saveAs(blob, `${decodeURI(name)}`)
        })
        .catch(() => {
        })
    }

    const deleteAttachFile = fileId => {
      bvModal
        .msgBoxConfirm('첨부 파일 삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/notice/file/${fileId}`)
            .then(() => {
              pushToast('success', '첨부 파일 삭제 완료')

              fetchNotice(noticeData.value.id)
            })
            .catch(() => {
            })
        })
    }

    const fetchNotice = id => {
      axios.get(`/fa/notice/${id}`)
        .then(rs => {
          noticeData.value = rs.data
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const fetchNotices = (ctx, callback) => {
      axios.post('/fa/notice/list', {
        search: {
          filterStr: query.value.filterStr,
          userName: query.value.userName,
          createDateFr: query.value.createDateFr ? `${query.value.createDateFr}T00:00:00` : null,
          createDateTo: query.value.createDateTo ? `${query.value.createDateTo}T23:59:59` : null,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord
        })
        .catch(() => {
          pushToast('danger', '데이터 조회에 실패하였습니다.')
        })
    }

    const saveNotice = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (!valid) return

        const { id } = noticeData.value

        if (id) {
          updateNotice()
        } else {
          createNotice()
        }
      })
    }

    const createNotice = () => {
      isSaving.value = true
      const formdata = new FormData()

      formdata.append("Title", noticeData.value.title)
      formdata.append("Contents", noticeData.value.contents === null ? '' : noticeData.value.contents)
      formdata.append("IsFixed", noticeData.value.isFixed)
      formdata.append("IsPublic", noticeData.value.isPublic)
      formdata.append("AllowComments", noticeData.value.allowComments)
      formdata.append("IsPopup", noticeData.value.isPopup)
      formdata.append("PopupDateFr", noticeData.value.popupDateFr ? noticeData.value.popupDateFr : dayjs().format('YYYY-MM-DD'))
      formdata.append("PopupDateTo", noticeData.value.popupDateTo ? noticeData.value.popupDateTo : dayjs().format('YYYY-MM-DD'))
      formdata.append("PopupAgainDays", noticeData.value.popupAgainDays)
      formdata.append("IsUploadContents", noticeData.value.isUploadContents)
      formdata.append("FixedType", noticeData.value.fixedType ? noticeData.value.fixedType : 1)

      formdata.append("popupImage", popupImageFile.value)

      for (let i = 0; i < attachFiles.value.length; i += 1) {
        formdata.append("attachFiles", attachFiles.value[i])
      }
      axios.post("/fa/notice/new", formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(rs => {
          pushToast('success', '공지사항 등록 완료')
          if (rs.data.id) {
            noticeData.value.id = rs.data.id
          }
          refetchData()
          isSaving.value = false
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
          isSaving.value = false
        })
    }

    const updateNotice = () => {
      const formdata = new FormData()

      formdata.append("Id", noticeData.value.id)
      formdata.append("Title", noticeData.value.title)
      formdata.append("Contents", noticeData.value.contents)
      formdata.append("IsFixed", noticeData.value.isFixed)
      formdata.append("IsPublic", noticeData.value.isPublic)
      formdata.append("AllowComments", noticeData.value.allowComments)
      formdata.append("IsPopup", noticeData.value.isPopup)
      formdata.append("PopupDateFr", noticeData.value.popupDateFr ? noticeData.value.popupDateFr : dayjs().format('YYYY-MM-DD'))
      formdata.append("PopupDateTo", noticeData.value.popupDateTo ? noticeData.value.popupDateTo : dayjs().format('YYYY-MM-DD'))
      formdata.append("PopupAgainDays", noticeData.value.popupAgainDays)
      formdata.append("IsUploadContents", noticeData.value.isUploadContents)
      formdata.append("FixedType", noticeData.value.fixedType ? noticeData.value.fixedType : 1)

      if (popupImageFile.value) formdata.append("popupImage", popupImageFile.value)

      for (let i = 0; i < attachFiles.value.length; i += 1) {
        formdata.append("attachFiles", attachFiles.value[i])
      }

      axios.put('/fa/notice/update', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          pushToast('success', '공지사항 변경 완료')

          if (popupImageFile.value != null && popupImageFile.value.length > 0) deletePrevPopupImage(noticeData.value.popupImageFileId)
          refetchData()
        })
        .catch(() => {
          pushToast('danger', '데이터를 저장하는 과정에서 오류가 발생하였습니다.')
        })
    }

    const deletePrevPopupImage = fileId => {
      axios.delete(`/fa/notice/popup-image/${fileId}`)
        .then(() => {
        })
        .catch(() => {
        })
    }

    const removeNotice = () => {
      if (!noticeData.value.id) {
        pushToast('warning', '삭제할 대상을 선택하세요.')
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/notice/${noticeData.value.id}`)
            .then(() => {
              pushToast('success', '데이터 삭제 완료')

              refetchData()
              resetNoticeData()
            })
            .catch(err => {
              console.log('error', err)
              pushToast('danger', '데이터를 삭제하는 과정에서 오류가 발생하였습니다.')
            })
        })
    }

    return {
      query,
      codes,
      noticeData,
      tableColumns,
      refNoticeTable,
      sortBy,
      isSortDesc,
      required,
      refetchData,
      totalRecords,
      fetchNotices,
      resetNoticeData,
      onRowSelectedNotice,
      saveNotice,
      removeNotice,
      inputFiles,
      isSaving,
      changeIsFixed,
      readContentFile,
      downloadContent,
      previewUrl,
      showModal,
      previewFrame,
      hasNoticeContents,
      loadPreview,
      inputPopupImage,
      popupImageFile,
      downloadPopupImage,
      downloadAttachFile,
      deleteAttachFile,
      attachFiles,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style>
table { table-layout: fixed; }

#noticePreview .modal-dialog {
  max-width: 1304px;
}
.tooltip .tooltip-inner{
  max-width: 440px !important;
}
</style>
